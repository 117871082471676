import React, { Component } from "react";
import { useContext } from "react";
import ProductCard from "./ProductCard";
import RangeSlider from "react-bootstrap-range-slider";
import { MessageBox } from "./MessageBox";
import { Button, SplitButton, Badge } from "react-bootstrap";
import { Stack, Table, Form, Row, Col } from "react-bootstrap";
import CartContext from "./CartContext";
import { useState } from "react";
import { LoadSpinner } from "./LoadSpinner";
import { Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ProductsListMobile } from "./ProductsListMobile.js";
//import ReactPaginate from "https://cdn.skypack.dev/react-paginate@7.1.3";
import "../CSS/pagination.css";
import ReactPaginate from "react-paginate";

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}

export class ProductsList extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      weightdefault: "",
      priceRangeCurrentValue: 200,
      priceRangeMinValue: 0,
      priceRangeMaxValue: 100,
      discountFilter: 0,
      title: "",
      selectedMainCategoryId: 0, //ALL
      selectedDiscount: 0,
      subCategoriesState: [],
      brandsState: [],
      prods: [],
      sorting: 1,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loading: true,
      maincategorynamearg: "",
      maincategorynameargloaded: false,
      reload: false,
      pageCount: 20,
      totalProductsInPage: 25,
      pageItemEnd: 25,
      pageItemStart: 0,
      reloadPaginate: false,
    };
    this.addToCart = this.addToCart.bind(this);
  }

  handleSortingChange = (e) => {
    this.ResetPaginate();

    this.setState({ sorting: e.target.value });
    if (e.target.value == 1) {
      var r = this.state.prods.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1));
      this.setState({ prods: r });
    } else if (e.target.value == 2) {
      var r = this.state.prods.sort((a, b) =>
        a.mrp - (a.mrp * a.discount) / 100 > b.mrp - (b.mrp * b.discount) / 100
          ? 1
          : -1
      );
      this.setState({ prods: r });
    } else if (e.target.value == 3) {
      var r = this.state.prods.sort((a, b) =>
        a.mrp - (a.mrp * a.discount) / 100 < b.mrp - (b.mrp * b.discount) / 100
          ? 1
          : -1
      );
      this.setState({ prods: r });
    } else if (e.target.value == 4) {
      this.state.prods.sort((a, b) => (a.discount > b.discount ? 1 : -1));
      this.setState({ prods: this.state.prods });
    } else if (e.target.value == 5) {
      this.state.prods.sort((a, b) => (a.discount < b.discount ? 1 : -1));
      this.setState({ prods: this.state.prods });
    }
  };

  setRangeValue = (value) => {
    this.setState({ priceRangeCurrentValue: value });
  };
  addToCart = (cartItem) => {
    this.props.addToCartProp(cartItem);
  };

  SetPriceRange = () => {
    var i =
      Math.max(
        ...this.context.prods.map((p) => p.mrp - (p.mrp * p.discount) / 100)
      ) + 20;
    this.setState({ priceRangeMaxValue: i });
    this.setState({ priceRangeCurrentValue: i });
  };

  ResetPaginate = () => {
    this.setState({ pageItemEnd: this.state.totalProductsInPage });
    this.setState({ pageItemStart: 0 });
    this.setState({ reloadPaginate: true }, () => {
      this.setState({ reloadPaginate: false });
    });
  };

  ApplyFilter = () => {
    this.ResetPaginate();

    //1. Copy context prods to tempprods
    var tempprods = this.context.prods.sort((a, b) =>
      a.seqNo > b.seqNo ? 1 : -1
    );

    //2. Main Category
    if (this.state.selectedMainCategoryId == 0) {
    } else {
      tempprods = tempprods.filter(
        (e) => e.subCategory.mainCategoryId == this.state.selectedMainCategoryId
      );
    }

    //3. Subcategory checked?

    var prodssubcategoryFiltered = [];
    var filteredSubcat = this.state.subCategoriesState.filter(
      (f) => f.checked === true
    );
    if (filteredSubcat.length != 0) {
      filteredSubcat.map((e) => {
        var filtered = tempprods.filter((tf) => tf.subCategoryId == e.id);
        if (filtered.length > 0) {
          filtered.map((fil) => prodssubcategoryFiltered.push(fil));
        }
      });
      tempprods = prodssubcategoryFiltered;
    }

    //3. brand Filtered
    var prodbrandFiltered = [];
    var filteredBrand = this.state.brandsState.filter(
      (f) => f.checked === true
    );
    if (filteredBrand.length != 0) {
      filteredBrand.map((e) => {
        var filtered = tempprods.filter((tf) => tf.brandId == e.id);
        if (filtered.length > 0) {
          filtered.map((fil) => prodbrandFiltered.push(fil));
        }
      });
      tempprods = prodbrandFiltered;
    }

    //4. PriceRange
    tempprods = tempprods.filter(
      (e) =>
        e.mrp - (e.mrp * e.discount) / 100 <=
        Number(this.state.priceRangeCurrentValue)
    );

    //5. PriceDiscount
    tempprods = tempprods.filter(
      (e) => e.discount >= Number(this.state.selectedDiscount)
    );

    //final. copy tempprods to this.state.prods
    this.setState({ prods: tempprods });
  };

  onPriceRangeChange = (e) => {
    this.setState({ priceRangeCurrentValue: e.target.value }, () =>
      this.ApplyFilter()
    );
  };
  onMainCategorySelectChangeEvent = (e) => {
    this.setState({ selectedMainCategoryId: e.target.value }, () => {
      var result = [];

      //ALL
      if (this.state.selectedMainCategoryId == 0) {
        this.setState({ subCategoriesState: [] }, () => {
          this.context.subCategories.map((sub) => {
            this.state.subCategoriesState.push({
              name: sub.name,
              id: sub.id,
              checked: false,
            });
            this.setState({
              subCategoriesState: this.state.subCategoriesState,
            });
          });
        });
      } else {
        this.setState({ subCategoriesState: [] }, () => {
          result = this.context.subCategories.filter(
            (f) => f.mainCategoryId == e.target.value
          );

          result.map((sub) => {
            this.state.subCategoriesState.push({
              name: sub.name,
              id: sub.id,
              checked: false,
            });
          });

          this.setState({ subCategoriesState: this.state.subCategoriesState });
        });
      }
      this.ApplyFilter();
    });
  };

  onSubCategorySelectChangeEvent = (e, row) => {
    //update
    this.state.subCategoriesState.map((subc) =>
      subc.name === e.target.name ? (subc.checked = e.target.checked) : ""
    );
    this.setState({ subCategoriesState: this.state.subCategoriesState }, () =>
      this.ApplyFilter()
    );
  };

  onBrandChange = (e, row) => {
    this.state.brandsState.map((subc) =>
      subc.brandName === e.target.name ? (subc.checked = e.target.checked) : ""
    );
    this.setState({ brandsState: this.state.brandsState }, () =>
      this.ApplyFilter()
    );
  };

  onChangeDiscount = (e) => {
    this.setState({ selectedDiscount: Number(e.target.value) }, () =>
      this.ApplyFilter()
    );
  };

  //this validation is required when same maincategory menu item is clicked sebsequently
  //TODO to be deleted: unused
  ValidateAndReloadSubcategory() {
    if (this.context.productListViewType === "MainCategory") {
      //Valiate
      var isCorrect = false;
      if (this.state.subCategoriesState.length > 0) {
        var filteredSubCat = this.context.subCategories.filter(
          (f) => f.mainCategoryId === this.context.productListViewMainCategoryId
        );

        filteredSubCat.map((e1) => {
          this.state.subCategoriesState.map((e) => {
            if (e1.name === e.name) {
              isCorrect = true;
            }
          });
        });
      }

      if (isCorrect != true) {
        this.setState({ subCategoriesState: [] }, () => {
          this.context.subCategories
            .filter(
              (f) =>
                f.mainCategoryId === this.context.productListViewMainCategoryId
            )
            .map((sub) => {
              if (
                this.state.subCategoriesState.filter((f) => f.name === sub.name)
                  .length == 0
              ) {
                this.state.subCategoriesState.push({
                  name: sub.name,
                  id: sub.id,
                  checked: false,
                });
              }
            });
          this.setState({ subCategoriesState: this.state.subCategoriesState });
        });
      }
    }
  }
  ResetFilterClick = () => {
    this.InitializeComponent();
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 100));
  };

  GetBrands = () => {
    var brands = [];
    this.context.prods.map((p) => {
      if (
        brands.filter((f) => f.id == p.brandId).length == 0 &&
        p.brandId != null
      ) {
        if (this.context.brands.filter((f) => f.id == p.brandId).length == 1) {
          brands.push({
            id: p.brandId,
            brandName: this.context.brands.filter((f) => f.id == p.brandId)[0]
              .brandName,
            checked: false,
          });
        }
      }
    });
    if (brands.length > 0) {
      this.setState({ brandsState: brands });
    }
  };

  componentDidMount() {
    this.context.storePreviousPage("");
    if (this.context.prods.length < 100) {
      this.setState({ loading: false }); // showing the app
    } else {
      this.fakeRequest().then(() => {
        this.setState({ loading: false }); // showing the app
      });
    }
    this.InitializeComponent();
    window.scrollTo(0, 0);
  }
  componentDidUpdate() {
    if (this.context.reloadProductListView === "true") {
      this.InitializeComponent();
    }
    if (this.context.refreshIncDecControl == true) {
      this.setState({ reload: true }, () => {
        this.setState({ reload: false });
        this.context.SetRefreshIncDecControl(false);
      });
    }
  }

  GetProductlistDivWidth = () => {
    var elem = document.getElementById("productlistdiv");
    if (elem) {
      var rect = elem.getBoundingClientRect();
      return rect.width;
    }
    return 1500;
  };

  InitializeComponent() {
    this.ResetPaginate();

    this.setState(
      {
        prods: JSON.parse(
          JSON.stringify(
            this.context.prods.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
          )
        ),
      },
      () => this.SetPriceRange()
    );
    this.GetBrands();
    this.setState({ selectedDiscount: 0 });

    if (this.context.productListViewType === "ProductGroup") {
      this.setState({ maincategorynameargloaded: true });
      this.context.subCategories.map((sub) => {
        if (
          this.state.subCategoriesState.filter((f) => f.name === sub.name)
            .length == 0
        ) {
          this.state.subCategoriesState.push({
            name: sub.name,
            id: sub.id,
            checked: false,
          });
        }
        this.setState({ subCategoriesState: this.state.subCategoriesState });
      });
    } else if (this.context.productListViewType === "MainCategory") {
      this.setState({ maincategorynameargloaded: true });
      this.setState({ subCategoriesState: [] }, () => {
        this.context.subCategories
          .filter(
            (f) =>
              f.mainCategoryId === this.context.productListViewMainCategoryId
          )
          .map((sub) => {
            if (
              this.state.subCategoriesState.filter((f) => f.name === sub.name)
                .length == 0
            ) {
              this.state.subCategoriesState.push({
                name: sub.name,
                id: sub.id,
                checked: false,
              });
            }
          });
        this.setState({ subCategoriesState: this.state.subCategoriesState });
      });
    }
    this.context.UpdateReloadProductListView("false");
  }

  handlePageClick = (event) => {
    var startIndex =
      (event.selected + 1) * this.state.totalProductsInPage -
      this.state.totalProductsInPage;

    //change end value first to avoid hanging
    this.setState({
      pageItemEnd: startIndex + this.state.totalProductsInPage,
    });

    this.setState({
      pageItemStart: startIndex,
    });
    window.scrollTo(0, 0);
  };

  render() {
    const { loading } = this.state;

    if (this.context.storeId == "") return <Navigate to="/home" />;

    if (loading) {
      return (
        <>
          <LoadSpinner open="true"></LoadSpinner>
        </>
      );
    }

    return (
      <>
        {this.state.reload == false ? (
          <>
            <div
              style={{
                backgroundColor: this.context.theme.homepageBackgroundColor,
              }}
            >
              <Helmet>
                <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
                <link href="dist/css/style.css" rel="stylesheet" />
                <link href="dist/css/responsive.css" rel="stylesheet" />
                <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
                <script src="dist/js/custom.js"></script>
              </Helmet>
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              {window.location.hostname == "shop.green.com.pg" ? (
                <>
                  <GreenAd></GreenAd>
                </>
              ) : (
                <></>
              )}
              <Row>
                <Col>
                  {this.context.prodListImgFilename != "" &&
                  this.context.prodListImgFilename != undefined ? (
                    <>
                      <div>
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            this.context.prodListImgFilename +
                            this.context.store.storageSasToken
                          }
                          width="100%"
                          // height="400px"
                          // style={{ objectFit: "contain" }}
                        ></img>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <div
                className="Container-fluid mx-5  "
                style={{
                  backgroundColor: this.context.theme.homepageBackgroundColor,
                }}
              >
                <Row>
                  {this.context.theme.hideFilters == true ? (
                    <></>
                  ) : (
                    <>
                      <Col className="py-3 pt-0" xs={2}>
                        <br />
                        <div className=" filterDsn">
                          <div
                            className="  my-4 p-2"
                            style={{
                              backgroundColor:
                                this.context.theme.homepageBackgroundColor,
                            }}
                          >
                            <Table
                              className="my-0 p-5 "
                              style={{
                                backgroundColor:
                                  this.context.theme.homepageBackgroundColor,
                              }}
                            >
                              <Row>
                                <Col>
                                  <div className="text-center  w-100">
                                    <h5
                                      style={{
                                        // backgroundColor:
                                        //   this.context.theme.homepageBackgroundColor,
                                        color:
                                          this.context.theme.filtersTextColor,
                                      }}
                                    >
                                      <b>Filters</b>
                                    </h5>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className=""
                                  style={{
                                    color: this.context.theme.filtersTextColor,
                                  }}
                                >
                                  <div className="text-end">
                                    {window.location.hostname ==
                                    "shop.green.com.pg" ? (
                                      <>
                                        <Button
                                          className="btn-green-2  text-white "
                                          variant="secondary"
                                          onClick={this.ResetFilterClick}
                                        >
                                          <small>Reset Filter</small>
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          style={{
                                            backgroundColor:
                                              this.context.theme
                                                .mainNavBarBackgroundColor,
                                            color:
                                              this.context.theme
                                                .mainNavBarTextColor,
                                          }}
                                          variant="secondary"
                                          onClick={this.ResetFilterClick}
                                        >
                                          <small>Reset Filter</small>
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                  {this.context.productListViewType ===
                                  "ProductGroup" ? (
                                    <>
                                      <br></br>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {this.context.productListViewType ===
                                  "MainCategory" ? (
                                    <>
                                      <h6 className="py-2 filterSubTitle">
                                        <b>Category</b>
                                      </h6>
                                      <div className="border categorydiv">
                                        {this.state.subCategoriesState.map(
                                          (subcategory) => (
                                            <h6>
                                              <Form.Check
                                                type="checkbox"
                                                size=""
                                                name={subcategory.name}
                                                label={subcategory.name}
                                                id={subcategory.id}
                                                onChange={
                                                  this
                                                    .onSubCategorySelectChangeEvent
                                                }
                                              ></Form.Check>
                                            </h6>
                                          )
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {this.state.brandsState.length > 1 ? (
                                    <>
                                      <br></br>
                                      <h6 className="py-2 ">
                                        <b>
                                          {this.context.store.businessTypeId ==
                                          20
                                            ? "Artist"
                                            : "Brand"}
                                          {/* <b>Brand</b> */}
                                        </b>
                                      </h6>
                                      <div className="border categorydiv">
                                        {this.state.brandsState.map((brand) => (
                                          <h6>
                                            <Form.Check
                                              type="checkbox"
                                              size=""
                                              name={brand.brandName}
                                              label={brand.brandName}
                                              checked={brand.checked}
                                              id={brand.id}
                                              onChange={this.onBrandChange}
                                            ></Form.Check>
                                          </h6>
                                        ))}
                                      </div>
                                      <br></br>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <br />

                                  {this.context.theme.hidePriceRangeOnFilters ==
                                  true ? (
                                    <></>
                                  ) : (
                                    <>
                                      <h6 className="py-2 filterSubTitle ">
                                        <b>Price Range</b>
                                      </h6>

                                      <div className="border">
                                        <Row className="w-100 p-0 m-0">
                                          <Col className="text-start">
                                            {this.state.priceRangeMinValue.toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}{" "}
                                          </Col>
                                          <Col className="text-end">
                                            {this.state.priceRangeMaxValue.toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}
                                          </Col>
                                        </Row>
                                        <RangeSlider
                                          value={
                                            this.state.priceRangeCurrentValue
                                          }
                                          size=""
                                          onChange={(e) =>
                                            this.onPriceRangeChange(e)
                                          }
                                          tooltipPlacement="bottom"
                                          tooltip="auto"
                                          min={this.state.priceRangeMinValue}
                                          max={this.state.priceRangeMaxValue}
                                          step={10}
                                        />
                                      </div>
                                      <br></br>
                                    </>
                                  )}

                                  {this.context.theme.hideDiscountOnFilters ==
                                  true ? (
                                    <></>
                                  ) : (
                                    <>
                                      <h6 className="py-2 filterSubTitle">
                                        <b>Discount</b>
                                      </h6>
                                      <div className="border">
                                        {/* <h6 className="filterDiscountData"> */}
                                        <h6 className="">
                                          <Row className="py-2">
                                            <Col>
                                              <Stack direction="horizontal">
                                                <Form.Check
                                                  id="all0"
                                                  type="radio"
                                                  label="All"
                                                  group="discount"
                                                  value="0"
                                                  checked={
                                                    this.state
                                                      .selectedDiscount === 0
                                                  }
                                                  onChange={
                                                    this.onChangeDiscount
                                                  }
                                                  className="checkAll"
                                                ></Form.Check>
                                                {/*<label
                                          htmlFor="all0"
                                          onChange={this.onChangeDiscount}
                                        >
                                          &nbsp;&nbsp;All
                                        </label>*/}
                                              </Stack>
                                            </Col>
                                            <Col></Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Stack direction="horizontal">
                                                <Form.Check
                                                  id="above5"
                                                  type="radio"
                                                  label="5% and Above "
                                                  group="discount"
                                                  value="5"
                                                  checked={
                                                    this.state
                                                      .selectedDiscount === 5
                                                  }
                                                  onChange={
                                                    this.onChangeDiscount
                                                  }
                                                ></Form.Check>
                                                {/*<label
                                          htmlFor="above5"
                                          onChange={this.onChangeDiscount}
                                        >
                                          &nbsp;&nbsp;Above 5%
                                        </label>*/}
                                              </Stack>
                                            </Col>
                                            <Col>
                                              <Stack direction="horizontal">
                                                <Form.Check
                                                  id="above10"
                                                  type="radio"
                                                  label="10% and Above "
                                                  group="discount"
                                                  value="10"
                                                  checked={
                                                    this.state
                                                      .selectedDiscount === 10
                                                  }
                                                  onChange={
                                                    this.onChangeDiscount
                                                  }
                                                ></Form.Check>
                                                {/*<label
                                          htmlFor="above10"
                                          onChange={this.onChangeDiscount}
                                        >
                                          &nbsp;&nbsp;Above 10%
                                        </label>*/}
                                              </Stack>
                                            </Col>
                                          </Row>
                                          <Row className="py-2">
                                            <Col>
                                              <Stack direction="horizontal">
                                                <Form.Check
                                                  id="above20"
                                                  type="radio"
                                                  label="20% and Above "
                                                  group="discount"
                                                  value="20"
                                                  checked={
                                                    this.state
                                                      .selectedDiscount === 20
                                                  }
                                                  onChange={
                                                    this.onChangeDiscount
                                                  }
                                                ></Form.Check>
                                                {/*<label
                                          htmlFor="above20"
                                          onChange={this.onChangeDiscount}
                                        >
                                          &nbsp;&nbsp;Above 20%
                                        </label>*/}
                                              </Stack>
                                            </Col>
                                            <Col>
                                              <Stack direction="horizontal">
                                                <Form.Check
                                                  id="above30"
                                                  type="radio"
                                                  label="30% and Above "
                                                  group="discount"
                                                  value="30"
                                                  checked={
                                                    this.state
                                                      .selectedDiscount === 30
                                                  }
                                                  onChange={
                                                    this.onChangeDiscount
                                                  }
                                                ></Form.Check>
                                                {/*<label
                                          htmlFor="above30"
                                          onChange={this.onChangeDiscount}
                                        >
                                          &nbsp;&nbsp;Above 30%
                                        </label>*/}
                                              </Stack>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Stack direction="horizontal">
                                                <Form.Check
                                                  id="above50"
                                                  type="radio"
                                                  label="50% and Above "
                                                  group="discount"
                                                  value="50"
                                                  checked={
                                                    this.state
                                                      .selectedDiscount === 50
                                                  }
                                                  onChange={
                                                    this.onChangeDiscount
                                                  }
                                                ></Form.Check>
                                                {/*<label
                                          htmlFor="above50"
                                          onChange={this.onChangeDiscount}
                                        >
                                          &nbsp;&nbsp;50% and Above
                                        </label>*/}
                                              </Stack>
                                            </Col>
                                            <Col>
                                              <Stack direction="horizontal">
                                                <Form.Check
                                                  id="above75"
                                                  type="radio"
                                                  label="75% and Above "
                                                  group="discount"
                                                  value="75"
                                                  checked={
                                                    this.state
                                                      .selectedDiscount === 75
                                                  }
                                                  onChange={
                                                    this.onChangeDiscount
                                                  }
                                                ></Form.Check>
                                                {/* <label
                                          htmlFor="above75"
                                          onChange={this.onChangeDiscount}
                                        >
                                          &nbsp;&nbsp;75% and Above 
                                        </label>*/}
                                              </Stack>
                                            </Col>
                                          </Row>
                                        </h6>
                                      </div>
                                      <br></br>
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </Table>
                          </div>
                        </div>
                      </Col>
                    </>
                  )}

                  <Col
                    xs={this.context.theme.hideFilters == true ? 12 : 10}
                    className="graybackground p-3 border-0"
                  >
                    <Row>
                      <Col xs={12} className="text-center">
                        <div class="title-dsn text-center">
                          <h2
                            class="bold_font"
                            style={{
                              color: this.context.theme.homepageTitlesTextColor,
                            }}
                          >
                            {this.context.prodListTitle}
                          </h2>
                        </div>
                      </Col>
                    </Row>

                    <div class="col-md-10 offset-md-1  ">
                      <div class="title-dsn text-center">
                        <span
                          style={{
                            color: this.context.theme.homepageTitlesTextColor,
                          }}
                        >
                          {/* <h5>{this.context.prodListDescription}</h5> */}
                          <p
                            style={{
                              fontSize: "16px",
                              textAlign: "justify",
                              fontFamily: "cursive",
                              // fontStyle: "revert",
                            }}
                          >
                            {this.context.prodListDescription}
                          </p>

                          <p>
                            {this.context.theme.hideProductCount == false ? (
                              <>{this.state.prods.length} Products available</>
                            ) : (
                              ""
                            )}
                          </p>
                        </span>
                      </div>
                    </div>
                    <Helmet>
                      <title>{this.context.prodListTitle}</title>
                      {/* <meta content="Online Store" name="keywords" /> */}
                      <meta
                        content={this.context.prodListDescription}
                        name="description"
                      />
                    </Helmet>

                    <Row>
                      <Col
                        className="text-start"
                        xs={7}
                        style={{
                          color: this.context.theme.homepageTitlesTextColor,
                        }}
                      ></Col>
                      <Col className="text-success text-center " xs={5}>
                        <h5>
                          <Row className="mx-2">
                            {/* <Col className="text-end align-middle p-0"> */}
                            <Col className="text-end align-middle mt-1 p-0">
                              <b>Sort by:</b>
                            </Col>
                            <Col className="text-end align-middle">
                              <Form.Select
                                value={this.state.sorting}
                                size="sm"
                                className="fw-bold"
                                onChange={this.handleSortingChange}
                              >
                                <option value={1}>Popularity</option>
                                <option value={2}>Price - Low To High</option>
                                <option value={3}>Price - High to Low</option>
                                <option value={4}>
                                  Discounts - Low to High
                                </option>
                                <option value={5}>
                                  Discounts - High to Low
                                </option>
                              </Form.Select>
                            </Col>
                          </Row>
                        </h5>
                      </Col>
                      {/* <Col xs={1}></Col> */}
                    </Row>
                    <div className="row mt-2 mx-1 px-0 border-0">
                      <div className="col-md-12 " id="productlistdiv">
                        <div className="row justify-content-around py-2 ">
                          {this.state.prods
                            .slice(
                              this.state.pageItemStart,
                              this.state.pageItemEnd
                            )
                            .map((prod) =>
                              window.location.hostname ==
                              "shop.green.com.pg" ? (
                                <>
                                  <div
                                    className="card p-1 mb-5 m-0 cardgreen"
                                    style={{
                                      width:
                                        this.context.theme.productCardWidth +
                                        "cm",
                                      backgroundColor:
                                        this.context.theme
                                          .productCardBackgroundColor,
                                      borderColor:
                                        this.context.theme
                                          .productCardBorderColor,
                                    }}
                                  >
                                    <div className="p-0 m-0">
                                      <ProductCard prod={prod}></ProductCard>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="card p-0 mb-5 mx-1  pb-0 CornersRounded "
                                    style={{
                                      width:
                                        ((window.innerWidth / 12) * 10) /
                                          (this.context.theme
                                            .slidesToShowInProductList
                                            ? this.context.theme
                                                .slidesToShowInProductList
                                            : 4) -
                                        40 +
                                        "px",

                                      backgroundColor:
                                        this.context.theme
                                          .homepageBackgroundColor,
                                      borderColor:
                                        this.context.theme
                                          .productCardBorderColor,
                                    }}
                                  >
                                    <div className="p-0 m-0  ">
                                      <ProductCard
                                        // parent="singleview"
                                        prod={prod}
                                      ></ProductCard>
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                    {this.state.prods.length / this.state.totalProductsInPage >
                    1 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            padding: 20,
                            boxSizing: "border-box",
                            width: "100%",
                          }}
                        >
                          {this.state.reloadPaginate == false ? (
                            <>
                              <div
                                className=" w-100 justify-content-center"
                                style={{ display: "flex", fontSize: "22px" }}
                              >
                                <ReactPaginate
                                  nextLabel="next >"
                                  onPageChange={this.handlePageClick}
                                  // pageRangeDisplayed={3}
                                  pageRangeDisplayed={
                                    this.state.prods.length /
                                      this.state.totalProductsInPage >
                                    10
                                      ? "10"
                                      : "5"
                                  }
                                  marginPagesDisplayed={2}
                                  pageCount={
                                    this.state.prods.length /
                                    this.state.totalProductsInPage
                                  }
                                  previousLabel="< previous"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  breakLabel="..."
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  containerClassName="pagination"
                                  activeClassName="active"
                                  renderOnZeroPageCount={null}
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
export class GreenAd extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  GetMainCaegoryVideoFileName = () => {
    var maincategory = this.context.mainCategories.filter(
      (f) => f.id == this.context.productListViewMainCategoryId
    )[0];

    if (maincategory && maincategory.name == "GREEN SunShine")
      return "GSS_GreenSunshine_Video_V1.webm";
    if (maincategory && maincategory.name == "GREEN Em'Pawa")
      return "GREEN-EmPava-Animation-Video.webm";
    if (maincategory && maincategory.name == "GREEN SunSmart")
      return "GSS_GreenSunsmart_Video_V1.webm";
  };
  render() {
    return (
      <>
        <div className="my-2">
          <Helmet>
            {/* <link rel="icon" type="image/x-icon" href="dist/images/fav.ico" /> */}
            <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
            <link href="dist/css/style.css" rel="stylesheet" />
            <link href="dist/css/responsive.css" rel="stylesheet" />
            <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
            <script src="dist/js/custom.js"></script>
          </Helmet>
          <section class="slider_sec">
            <div
              id="carouselExampleCaptions"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <Row>
                    <Col xs={5}></Col>
                    <Col xs={7}></Col>
                  </Row>
                </div>
              </div>

              <div class="carousel-inner">
                <div class="carousel-item active">
                  <video
                    autoPlay
                    muted
                    width="100%"
                    height="auto"
                    preload="none"
                  >
                    <source
                      src={
                        this.context.store.storageBlobUrl +
                        this.context.store.storageBlobContainerName +
                        "/images/" +
                        this.GetMainCaegoryVideoFileName() +
                        this.context.store.storageSasToken
                      }
                      type="video/webm"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <div class="carousel-caption d-none d-md-block slider_white_bg">
                    <div class="row h-100  ">
                      <div class="col-md-auto ">
                        <div class="sm-grp">
                          <ul>
                            <li>
                              <a
                                href={this.context.storeSettings.facebookUrl}
                                target="_blank"
                              >
                                <img src="dist/images/sm-icon/fb.png" />
                              </a>
                            </li>
                            <li>
                              <a
                                href={this.context.storeSettings.linkedinUrl}
                                target="_blank"
                              >
                                <img src="dist/images/sm-icon/in.png" />
                              </a>
                            </li>
                            <li>
                              <a
                                href={this.context.storeSettings.instagramUrl}
                                target="_blank"
                              >
                                <img src="dist/images/sm-icon/ins.png" />
                              </a>
                            </li>
                            <li>
                              <a
                                href={this.context.storeSettings.twitterUrl}
                                target="_blank"
                              >
                                <img src="dist/images/sm-icon/tw.png" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.youtube.com/@greenlimited4405"
                                target="_blank"
                              >
                                <img src="dist/images/sm-icon/yt.png" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col  d-flex align-item-center ">
                        <div class="content_part  ">
                          {this.context.mainCategories
                            .filter(
                              (f) =>
                                f.id ==
                                this.context.productListViewMainCategoryId
                            )
                            .map((m) => (
                              <>
                                {m.name == "GREEN SunShine" ? (
                                  <>
                                    <div class="product-brand">
                                      <img src="dist/grnimages/products/sunshine-logo.png" />
                                    </div>
                                    <h1>
                                      Lighting-up and Lifting-up Living
                                      Standards
                                    </h1>
                                    <div class="content_des mt-5 mb-5 ">
                                      <h4 class="bold">
                                        Solar PV Home product for basic
                                        life-style
                                      </h4>
                                      <p>
                                        GREEN SunShine, an intrinsic lighting
                                        provision series with small to large
                                        Solar Home Lighting Systems to meet the
                                        electrification needs for varied
                                        households in rural areas.
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {m.name == "GREEN Em'Pawa" ? (
                                  <>
                                    <div class="product-brand">
                                      <img src="dist/grnimages/products/empower-logo.png" />
                                    </div>
                                    <h1>
                                      Energizing Communities, Empowering Papua
                                      New Guinea
                                    </h1>
                                    <div class="content_des mt-5 mb-5 ">
                                      <h4 class="bold">
                                        Tailored Solutions for High-Demand Power
                                        Needs
                                      </h4>
                                      <p>
                                        GREEN Em'Pawa, a tailored Solar Power
                                        System Solution for high-demand needs.
                                        With capacities surpassing 1kW, it
                                        guarantees reliable power for critical
                                        operations while efficiently meeting
                                        diverse energy needs and reducing costs
                                        and environmental impact.
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {m.name == "GREEN SunSmart" ? (
                                  <>
                                    <div class="product-brand">
                                      <img src="dist/grnimages/products/sun-smart-logo.png" />
                                    </div>
                                    <h1>
                                      Creating & Accelerating Micro Economies
                                    </h1>
                                    <div class="content_des mt-5 mb-5 ">
                                      <h4 class="bold">
                                        Augmenting Renewable Energy with Agro
                                        Technologies
                                      </h4>
                                      <p>
                                        GREEN SunSmart, envisioned to deliver
                                        renewable energy solutions to empower
                                        farmers, enhances agriculture
                                        production, post harvesting process with
                                        solar powered agriculture technological
                                        equipment and systems.
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="count_sec">
            <div class="container">
              <div class="row">
                <div class="col-md-3 col-sm-6">
                  <div class="count-grp text-center">
                    <h3 class="bold_font">
                      <span class="count">652</span>+{" "}
                    </h3>
                    <h5 class="mb-0">Lives Impacted to Date</h5>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="count-grp text-center">
                    <h3 class="bold_font">
                      <span class="count">99</span> %{" "}
                    </h3>
                    <h5 class="mb-0">Happy Customer</h5>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="count-grp text-center">
                    <h3 class="bold_font">
                      <span class="count">14</span>+{" "}
                    </h3>
                    <h5 class="mb-0">Year of Experience</h5>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="count-grp text-center">
                    <h3 class="bold_font">
                      <span class="count">200</span>+{" "}
                    </h3>
                    <h5 class="mb-0">Week Sales</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="hm_product_bg_1 pt-200px">
            <div class="container">
              <div class="row">
                <div class="col-md-3  col-sm-9">
                  <div class="title-dsn ">
                    <h2 class="bold_font cl-white">
                      Solar Home Lighting System
                    </h2>
                    <h5 class="cl-white">
                      We Light-up & Lift-up Living Standard
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
export default function HoverControlledSplitButton(props) {
  const { theme } = useContext(CartContext);
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <SplitButton
      {...props}
      style={{
        backgroundColor: theme.mainNavBarBackgroundColor,
        color: theme.mainNavBarTextColor,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      // onTouchStart={() => setIsHovered(true)}
      // onTouchEnd={() => setIsHovered(false)}
      onToggle={() => setIsClicked(!isClicked)}
      show={isClicked || isHovered}
      // className=" fw-bold text-start justify-content-start"
      // style={{
      //   justifyContent: "flex-start",
      //   textAlign: "left",
      //   float: "left",
      //   width: "200pt",
      // }}
    />
  );
}

export class ProductListViewByProductGroup extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ProductsList></ProductsList>
      </>
    );
  }
}
export class ProductListViewByMainCategory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      maincategorynamearg: "",
      maincategorynameargloaded: false,
    };
  }

  InitializeByMainCategoryNameArg = (mainCategoryname) => {
    if (this.state.maincategorynamearg == "")
      this.setState({ maincategorynamearg: mainCategoryname });
  };

  render() {
    return (
      <>
        <>
          <ProductsList></ProductsList>
        </>
      </>
    );
  }
}
export class ProductListViewBySubCategory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ProductsList></ProductsList>
      </>
    );
  }
}
export class MainCategoryArg extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      maincategorynamearg: "",
      maincategorynameargloaded: false,
    };
  }

  InitializeByMainCategoryNameArg = (mainCategoryname) => {
    if (this.state.maincategorynamearg == "")
      this.setState({ maincategorynamearg: mainCategoryname });
  };
  componentDidMount = () => {
    this.context.SetHomePageLoadedStatus();
  };

  render() {
    if (
      this.state.maincategorynamearg != "" &&
      this.state.maincategorynamearg != undefined &&
      this.state.maincategorynameargloaded == false &&
      this.context.mainCategories.length > 0
    ) {
      if (this.context.prodsAll.length != 0) {
        var maincategories =
          this.context.mainCategories &&
          this.context.mainCategories.filter(
            (f) =>
              f.name &&
              f.name.toUpperCase() ==
                this.state.maincategorynamearg.toUpperCase()
          );
        if (maincategories.length > 0) {
          this.context.GetProductsByMainCategoryId(maincategories[0].id);
        }
        this.setState({ maincategorynameargloaded: true }, () => {});
      }
    }
    if (this.state.maincategorynameargloaded == false) {
      return (
        <>
          <MinCategoryUserArg
            MainCategory={this.InitializeByMainCategoryNameArg}
          ></MinCategoryUserArg>
        </>
      );
    }
    return (
      <>
        {this.state.maincategorynameargloaded == true ? (
          <>
            {window.innerWidth >= 1200 ? (
              <>
                <ProductsList></ProductsList>
              </>
            ) : (
              <>
                <ProductsListMobile></ProductsListMobile>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
export class SubCategoryArg extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      subcategorynamearg: "",
      subcategorynameargloaded: false,
    };
  }

  InitializeBySubCategoryNameArg = (subCategoryname) => {
    if (this.state.subcategorynamearg == "")
      this.setState({ subcategorynamearg: subCategoryname });
  };

  render() {
    if (
      this.state.subcategorynamearg != "" &&
      this.state.subcategorynamearg != undefined &&
      this.state.subcategorynameargloaded == false &&
      this.context.subCategories.length > 0
    ) {
      if (this.context.prodsAll.length != 0) {
        var subcategories =
          this.context.subCategories &&
          this.context.subCategories.filter(
            (f) =>
              f.name &&
              f.name.toUpperCase() ==
                this.state.subcategorynamearg.toUpperCase()
          );
        if (subcategories.length > 0) {
          this.context.GetProductsBySubCategoryId(subcategories[0].id);
        }
        this.setState({ subcategorynameargloaded: true }, () => {});
      }
    }
    if (this.state.subcategorynameargloaded == false) {
      return (
        <>
          <SubCategoryUserArg
            SubCategory={this.InitializeBySubCategoryNameArg}
          ></SubCategoryUserArg>
        </>
      );
    }
    return (
      <>
        {this.state.subcategorynameargloaded == true ? (
          <>
            {window.innerWidth >= 1200 ? (
              <>
                <ProductsList></ProductsList>
              </>
            ) : (
              <>
                <ProductsListMobile></ProductsListMobile>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const MinCategoryUserArg = (props) => {
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("name")); // 'name'
  props.MainCategory(searchParams.get("name"));

  return <div></div>;
};

const SubCategoryUserArg = (props) => {
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("name")); // 'name'
  props.SubCategory(searchParams.get("name"));

  return <div></div>;
};
