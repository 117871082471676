import React, { Component, useState, useContext, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { FaQuestion } from "react-icons/fa";
import { FaRegQuestionCircle } from "react-icons/fa";
import { TbCoinRupee } from "react-icons/tb";

import {
  BsFillHeartFill,
  BsFillHouseDoorFill,
  BsPerson,
  BsHeart,
  BsCart2,
  BsBag,
  BsCash,
} from "react-icons/bs";
import {
  ControlledMenu,
  useHover,
  useMenuState,
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { BiUserCircle } from "react-icons/bi";
import HoverControlledSplitButton from "./ProductsList";
import RefreshBearerToken from "./RefreshBearerToken";
import StartAddedToCartTimer from "./AddedToCartTimer";
import WishListViewMobile from "./WishListViewMobile";
import UserLoginNewMobile from "./UserLoginNewMobile";

import {
  Badge,
  OverlayTrigger,
  Popover,
  ListGroup,
  Dropdown,
  Container,
  Row,
  Col,
  Stack,
} from "react-bootstrap";
import CartContext from "./CartContext";
import SearchViewMobile from "./SearchViewMobile";
import Select from "react-select";
import { getMainCategories } from "../Helper/ConsumerHelper";
import "../CSS/textanimation.css";
import { Navbar, Nav, Button, Form, FormControl } from "react-bootstrap";
import { render } from "@testing-library/react";

export default class NavigationMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { loginRefreshCounter: 1, storeId: "" };
  }

  refreshBearerToken = (newval) => {
    this.setState({ loginRefreshCounter: newval + 1 });
    console.log("refresh called");
    this.context.RefreshBearerToken(
      this.context.userData.emailId,
      this.context.userData.password,
      this.context.userData
    );
  };
  refreshBearerTokenWithMobileNo = (newval) => {
    this.setState({ loginRefreshCounter: newval + 1 });
    console.log("refresh called");
    this.context.refreshBearerTokenWithMobileNo(
      this.context.userData.mobileNo,
      this.context.userData
    );
  };

  GetMyStoreDetails = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/")
      .then((response) => response.json())
      .then((data) => {
        this.context.setStoreId(data.id);
        this.context.SetStore(data);
        if (this.state.storeId != "") return;
        this.setState({ storeId: data.id }, () => {
          if (new Date(data.storeEnd) > new Date() == false) {
            alert("Store is Inactive. Please contact your Vendor!");
            return;
          }
          //this.context.GetStore();
          this.context.GetStoreSettings();
          this.context.GetMultiCurrencys();
          this.context.GetDisplayCounter();
          this.context.GetVisitorsCount();
          this.context.GetMainCarousel(2); //1-desktop 2-Mobile
          this.context.GetOrderStatusTypes();
          this.context.getMainCategories();
          this.context.getSubCategories();
          this.context.getBrands();
          this.context.GetMainMenus();
          this.context.GetAllProducts();
          this.context.GetTop5Ads();
          this.context.GetProductGroups();
          this.context.getShippingDetails();
          this.context.getTaxDetails();
          this.context.StoreMyIpAddress();
          this.context.GetQuoteStatusTypes();
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("GetMyStoreDetails fetch err:" + error);
      });
  };

  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 100));
  };
  componentDidMount = () => {
    // if (this.state.storeId == "") {
    //   this.GetMyStoreDetails();
    // }
    const platform = window.navigator.platform;
    if (
      platform.includes("Mac") ||
      platform.includes("Sun") ||
      platform.includes("iPhone")
    ) {
      this.fakeRequest().then(() => {
        if (this.state.storeId == "") {
          this.GetMyStoreDetails();
        }
      });
    } else {
      if (this.state.storeId == "") {
        this.GetMyStoreDetails();
      }
    }
  };

  componentDidUpdate() {
    var countrycode = localStorage.getItem("CountryCode");
    if (
      countrycode &&
      this.context.islanguageSelected == false &&
      this.context.storeSettings.multiCurrencySupport == true &&
      this.context.multiCurrenciesSetting.length > 0 &&
      this.context.prodsAll.length > 0
    ) {
      this.context.SetSelectedCountryCode(countrycode, this.context.prodsAll);
      this.context.SetIslanguageSelected(true);
    }
  }

  HideUserLoginNew = () => {
    //this.setState({ ShowUserLoginNew: false });
    this.context.ShowUserLoginNew(false);
  };

  SignOut = () => {
    //this.setState({ ShowUserLoginNew: true });
  };

  render() {
    if (this.context.userData.loginStatus == "active") {
      if (this.context.prevPage != "") {
        var prevPage = this.context.prevPage;
        this.context.storePreviousPage("");
        return <Navigate to={prevPage} />;
      }
    }

    return (
      <>
        {this.context.AddedToCartMsg != "" ? (
          <StartAddedToCartTimer></StartAddedToCartTimer>
        ) : (
          ""
        )}

        {this.context.userData.loginStatus === "active" ? (
          <RefreshBearerToken></RefreshBearerToken>
        ) : (
          ""
        )}

        {this.context.store &&
        this.context.store.storeTypeId == this.context.storeType.Enterprise ? (
          <></>
        ) : (
          <>
            {this.context.portalMode == "CustomerPortal" ? (
              <>
                {this.context.theme.hideWhatsAppIcon == false ? (
                  <>
                    <a
                      class="whats-app "
                      href={
                        "https://wa.me/" +
                        this.context.storeSettings.defaultCountryCode +
                        this.context.storeSettings.storeWhatsappNo
                      }
                      target="_blank"
                    >
                      <i class="bi bi-whatsapp my-float"></i>
                      {/* <i class="fa fa-whatsapp my-float"></i> */}
                    </a>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <></>
            )}

            <MainNavigationBarMobile
              SignOut={this.SignOut}
            ></MainNavigationBarMobile>

            {/* <SubNavigationBar></SubNavigationBar> */}
            {this.context.showUserLoginNew === true ? (
              <UserLoginNewMobile
                show={this.context.showUserLoginNew}
                onHide={this.HideUserLoginNew}
              />
            ) : null}
          </>
        )}
      </>
    );
  }
}

class MainNavigationBarMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      showOverlay: false,
      showWishList: false,
      showOverlayCategoryMenu: false,
      proceedToQuote: false,
    };
  }

  OnClickWishList = (e) => {
    this.setState({ showWishList: true });
  };
  onCloseWishList = (e) => {
    this.setState({ showWishList: false });
  };
  handleOnMouseEnter = () => {
    //setShowOverlay(true);
    this.setState({ showOverlay: true });
  };
  handleOnMouseLeave = () => {
    //setShowOverlay(false);
    this.setState({ showOverlay: false });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      redirect: "/",
    });
  };

  handleOnMouseEnterCategoryMenu = () => {
    //setShowOverlay(true);
    this.setState({ showOverlayCategoryMenu: true });
  };
  handleOnMouseLeaveCategoryMenu = () => {
    //setShowOverlay(false);
    this.setState({ showOverlayCategoryMenu: false });
  };
  getMainCategoryById = (id) => {
    var pg = this.context.mainCategories.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };
  getSubCategoryById = (id) => {
    var pg = this.context.subCategories.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };
  getProductGroupById = (id) => {
    var pg = this.context.productGroups.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };
  GetRedirectPathForProductMenu(menu) {
    if (menu.productGroupId != null) return "/ProductListViewByProductGroup";
    if (menu.mainCategoryId != null)
      return "/ProductListViewByMainCategoryMobile";
    if (menu.subCategoryId != null)
      return "/ProductListViewBySubCategoryMobile";
  }
  GetMenuName = (menu) => {
    let name = "";
    if (menu.menutype == 1) {
      //main category
      name = this.getMainCategoryById(menu.mainCategoryId);
    } else if (menu.menutype == 2) {
      //sub category
      name = this.getSubCategoryById(menu.subCategoryId);
    } else if (menu.menutype == 3) {
      name = this.getProductGroupById(menu.productGroupId);
    }

    return name;
  };

  OnMenuClick = (e, menu) => {
    this.context.UpdateReloadProductListView("true");
    if (menu.menutype == 1) {
      //main category
      this.context.GetProductsByMainCategoryId(menu.mainCategoryId);
    } else if (menu.menutype == 2) {
      //sub category

      this.context.GetProductsBySubCategoryId(menu.subCategoryId, null, null);
    } else if (menu.menutype == 3) {
      this.context.GetProductsByProductgroupId(menu.productGroupId);
    }
  };

  componentDidMount() {
    console.log(this.context.userdata);
  }
  componentDidUpdate() {}

  hideMenu = () => {
    this.setState({ showOverlayCategoryMenu: false });
  };

  SignOut = () => {
    this.props.SignOut();
  };

  OnLogin = () => {
    this.context.ShowUserLoginNew(true);
  };
  SubCategoryClicked = () => {
    this.setState({ searchquery: "" }, () => {
      this.setState({ searchData: [] });
    });
    this.setState({ showProductListByCategory: true });
  };

  LogoClick = (e) => {
    if (
      window.location.pathname == "/ProductsListMobile" ||
      window.location.pathname == "/ProductListViewByMainCategoryMobile"
    ) {
      var cate = this.context.mainCategories.filter((f) =>
        f.name.includes(e.currentTarget.id)
      );
      if (cate.length > 0) {
        this.context.GetProductsByMainCategoryId(cate[0].id);
        this.context.SetSelectedGreenCategory(e.currentTarget.id);
      }
    } else {
      this.context.SetSelectedGreenCategory(e.currentTarget.id);
    }
  };
  GetRedirectPath = () => {
    if (this.context.prevPage == "Home") return "/HomeGreen1";
    var result =
      window.location.pathname == "/ProductsListMobile"
        ? "/ProductListViewByMainCategoryMobile"
        : window.location.pathname == "/ProductListViewByMainCategoryMobile"
        ? "/ProductsListMobile"
        : window.location.pathname == "/home"
        ? "/HomeGreen1"
        : window.location.pathname == "/HomeGreen1"
        ? "/home"
        : "/HomeGreen1";
    return result;
  };

  onCountryChange = (e) => {
    this.context.SetSelectedCountryCode(e.target.value);
    this.setState({ countryChanged: true }, () => {
      this.setState({ countryChanged: false });
    });
  };

  render() {
    if (window.location.hostname != "shop.green.com.pg") {
      if (this.state.showProductListByCategory == true) {
        this.setState({ showProductListByCategory: false });
        return <Navigate to="/ProductsListMobile" />;
      }
    }

    if (
      this.context.userData.loginStatus === "active" &&
      this.state.proceedToCheckOut == true
    ) {
      this.setState({ proceedToCheckOut: false }, () => {});
      return <Navigate to="/PayView" />;
    }

    if (
      this.context.userData.loginStatus === "active" &&
      this.state.proceedToQuote == true
    ) {
      this.setState({ proceedToQuote: false }, () => {});
      return <Navigate to="/QuotePage" />;
    }

    return (
      <>
        {this.state.showWishList ? (
          <WishListViewMobile
            onCloseWishList={this.onCloseWishList}
          ></WishListViewMobile>
        ) : (
          ""
        )}
        <Navbar
          className="fixed-top fixed-top-mob align-middle m-0 p-0 px-0 py-1 "
          style={{
            backgroundColor: this.context.theme.mainNavBarBackgroundColor,
          }}
        >
          {window.location.hostname == "shop.green.com.pg" ? (
            <></>
          ) : (
            <>
              <Navbar.Collapse className="justify-content-center border-0 m-0 p-0">
                <Nav className="border-0 m-0 p-0">
                  <ShopByCategoryMobile
                    SubCategoryClicked={this.SubCategoryClicked}
                  ></ShopByCategoryMobile>
                </Nav>
              </Navbar.Collapse>
            </>
          )}
          <Navbar.Collapse className="justify-content-end   m-1 p-0">
            {window.location.hostname == "shop.green.com.pg" ? (
              <>
                <Nav className="">
                  <Nav.Link
                    as={Link}
                    className=" navBarLink menufont verticalaligncentertd border-0 my-2"
                    to="/home"
                    onClick={(e) => this.context.storePreviousPage("Home")}
                  >
                    <BsFillHouseDoorFill
                      size={"22px"}
                      style={{
                        color: "#23B14D",
                      }}
                    ></BsFillHouseDoorFill>
                  </Nav.Link>
                  <div class="mob-nav-link ">
                    <div class="navProductMainmenu">
                      <img
                        src="dist/grnimages/products/sunshine-logo.png"
                        class="main-logo "
                        style={{
                          width:
                            this.context.theme.storeLogoImageWidth == undefined
                              ? "1cm"
                              : this.context.theme.storeLogoImageWidthMobile +
                                "cm",
                        }}
                      />
                    </div>
                    <div class="dropdown onSubMenuMob p-0 m-0">
                      <i class="bi bi-chevron-down"></i>
                    </div>
                    <div class="dropdown-list">
                      <ul>
                        <li class="navSubmenu" id="sunshineTab">
                          <img
                            src="dist/grnimages/products/sunshine-logo.png"
                            class="main-logo"
                            style={{
                              width:
                                this.context.theme.storeLogoImageWidth ==
                                undefined
                                  ? "1cm"
                                  : this.context.theme
                                      .storeLogoImageWidthMobile + "cm",
                            }}
                          />
                        </li>
                        <li class="navSubmenu" id="empawaTab">
                          <img
                            src="dist/grnimages/products/empower-logo.png"
                            class="main-logo"
                            style={{
                              width:
                                this.context.theme.storeLogoImageWidth ==
                                undefined
                                  ? "1cm"
                                  : this.context.theme
                                      .storeLogoImageWidthMobile + "cm",
                            }}
                          />
                        </li>
                        <li class="navSubmenu" id="sunsmartTab">
                          <img
                            src="dist/grnimages/products/sun-smart-logo.png"
                            class="main-logo"
                            style={{
                              width:
                                this.context.theme.storeLogoImageWidth ==
                                undefined
                                  ? "1cm"
                                  : this.context.theme
                                      .storeLogoImageWidthMobile + "cm",
                            }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Nav>
              </>
            ) : (
              <>
                <Stack direction="vertical">
                  <Nav className="m-0 p-0">
                    <Nav.Link
                      as={Link}
                      to="/home"
                      className=" align-middle  active m-0 p-0"
                    >
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.storeSettings.storeLogoFileName +
                          this.context.store.storageSasToken
                        }
                        alt="..."
                        onClick={(e) => {}}
                        className={"m-0 p-0 justify-content-start "}
                        style={{
                          cursor: "pointer",
                          width:
                            this.context.theme.storeLogoImageWidth == undefined
                              ? "1cm"
                              : this.context.theme.storeLogoImageWidthMobile +
                                "cm",
                          backgroundColor: "transparant",
                          filter:
                            "invert(" + this.context.theme.invertLogo + "%)",
                        }}
                      ></img>
                    </Nav.Link>
                  </Nav>
                  {/* <div className="border-0 text-center p-0 m-0">
                    <small
                      className="p-0 m-0  visitorscount"
                      style={{
                        fontFamily: "monospace",
                        fontSize: "11px",
                        color: this.context.theme.mainNavBarTextColor,
                      }}
                    >
                      Visitors:{" "}
                      <b>
                        <Link
                          to="/VisitorsCount"
                          style={{
                            color: this.context.theme.mainNavBarTextColor,
                            fontSize: "11px",
                          }}
                        >
                          <b>{this.context.visitorsCount}</b>
                        </Link>
                      </b>
                    </small>
                  </div> */}
                </Stack>
              </>
            )}
          </Navbar.Collapse>

          <Navbar.Collapse
            className="justify-content-start mx-0 p-0 border-0"
            style={{ paddingRight: "0px" }}
          >
            {this.context.storeSettings.multiCurrencySupport == true ? (
              <>
                <Nav className="mx-1 ">
                  <span
                    style={{
                      color: this.context.theme.mainNavBarTextColor,
                    }}
                  >
                    <Stack direction="horizontal">
                      {/* <label className="text-center">
                              <small>Country:</small>
                            </label> */}
                      &nbsp;
                      <Form.Select
                        type="text"
                        name="countryName"
                        size="sm"
                        value={this.context.selectedCountryCode}
                        required
                        className="text-start"
                        autoComplete="off"
                        onChange={(e) => this.onCountryChange(e)}
                      >
                        {this.context.multiCurrenciesSetting &&
                          this.context.multiCurrenciesSetting
                            .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                            .map((m) => (
                              <>
                                <option value={m.countryName}>
                                  {m.countryName}
                                </option>
                              </>
                            ))}
                      </Form.Select>
                    </Stack>
                  </span>
                </Nav>
              </>
            ) : (
              <></>
            )}

            {this.context.theme.allProductToGetQuote != true ? (
              <>
                {/* Search */}
                <Nav className="m-0 verticalaligncentertd">
                  <SearchViewMobile></SearchViewMobile>
                </Nav>
                {/* User Login*/}
                <Nav className=" mx-1">
                  {/* Signup/Login */}
                  <Button
                    variant=""
                    className="text-white align-middle my-2 "
                    hidden={this.context.userData.hideSignupLink}
                    onClick={this.OnLogin}
                  >
                    <BsPerson
                      style={{
                        color: this.context.theme.mainNavBarTextColor,
                      }}
                      size={"20px"}
                    ></BsPerson>
                  </Button>
                </Nav>
                {/* User Logged-in */}
                <Nav className="m-0 p-0 verticalaligncentertd ">
                  {/* User */}
                  <OverlayTrigger
                    show={this.state.showOverlay} // Control trigger behavior with show instead of trigger.
                    key="bottom"
                    placement="bottom"
                    rootClose="false"
                    overlay={
                      <Popover
                        id={`popover-positioned-bottom`}
                        onMouseEnter={this.handleOnMouseEnter}
                        onMouseLeave={this.handleOnMouseLeave}
                        className="navDsn"
                      >
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          <UserMenu SignOut={this.SignOut}></UserMenu>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      className="text-white m-0 p-0 verticalaligncentertd"
                      variant=""
                      hidden={!this.context.userData.hideSignupLink}
                      onMouseEnter={this.handleOnMouseEnter}
                      onMouseLeave={this.handleOnMouseLeave}
                    >
                      &nbsp;
                      <BsPerson
                        style={{
                          color: this.context.theme.mainNavBarTextColor,
                        }}
                        size={"18px"}
                      ></BsPerson>
                      &nbsp;&nbsp;
                    </Button>
                  </OverlayTrigger>
                </Nav>
                {/* Wishlist */}
                {window.location.hostname == "shop.green.com.pg" ? (
                  <></>
                ) : (
                  <>
                    <Nav className="verticalaligncentertd m-0 p-0 ">
                      <Nav.Link
                        className="m-0 p-0 text-center align-content-center"
                        onClick={(e) => this.OnClickWishList(e)}
                      >
                        <BsHeart
                          size={"16px"}
                          className=""
                          style={{
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                        ></BsHeart>
                        <Badge
                          bg={
                            this.context.theme.badgeBackgroundColor == undefined
                              ? "white"
                              : this.context.theme.badgeBackgroundColor
                          }
                          className="notify-badge-wishlist-mobile"
                          style={{
                            color: this.context.theme.badgeTextColor,
                            fontSize: "10px",
                          }}
                        >
                          {this.context.wishListProductIds.length}
                        </Badge>
                      </Nav.Link>
                    </Nav>
                  </>
                )}
                {/* Cart */}
                <Nav className="p-0 ">
                  <Nav.Link
                    as={Link}
                    className="p-0 verticalaligncentertd"
                    style={{ color: "white" }}
                    to="/Cart"
                  >
                    {this.context.theme.cartIcon == "Cart" ||
                    this.context.theme.cartIcon == undefined ? (
                      <>
                        <BsCart2
                          size={"18px"}
                          style={{
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                        ></BsCart2>
                      </>
                    ) : (
                      <>
                        <BsBag
                          size={"16px"}
                          style={{
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                        ></BsBag>
                      </>
                    )}
                    {window.location.hostname == "shop.green.com.pg" ? (
                      <>
                        <span
                          class="badge"
                          className="notify-badge-cart"
                          style={{
                            color: this.context.theme.badgeTextColor,
                            backgroundColor: "#23B14D",
                          }}
                        >
                          {this.context.getTotalQtyInCart(this.context.myCart)}
                        </span>
                      </>
                    ) : (
                      <>
                        <Badge
                          bg={
                            this.context.theme.badgeBackgroundColor == undefined
                              ? "white"
                              : this.context.theme.badgeBackgroundColor
                          }
                          className="notify-badge-cart-mobile"
                          style={{
                            color: this.context.theme.badgeTextColor,
                            fontSize: "10px",
                          }}
                        >
                          {this.context.getTotalQtyInCart(this.context.myCart)}
                        </Badge>
                      </>
                    )}
                  </Nav.Link>
                </Nav>
              </>
            ) : (
              <>
                <Nav className="m-2 verticalaligncentertd">
                  <SearchViewMobile></SearchViewMobile>
                </Nav>
                {/* User Login*/}
                <Nav className=" mx-2">
                  {/* Signup/Login */}
                  <Button
                    variant=""
                    className="text-white align-middle my-2 "
                    hidden={this.context.userData.hideSignupLink}
                    onClick={this.OnLogin}
                  >
                    <BsPerson
                      style={{
                        color: this.context.theme.mainNavBarTextColor,
                      }}
                      size={"20px"}
                    ></BsPerson>
                  </Button>
                </Nav>
                {/* User Logged-in */}
                <Nav className="m-0 p-0 verticalaligncentertd ">
                  {/* User */}
                  <OverlayTrigger
                    show={this.state.showOverlay} // Control trigger behavior with show instead of trigger.
                    key="bottom"
                    placement="bottom"
                    rootClose="false"
                    overlay={
                      <Popover
                        id={`popover-positioned-bottom`}
                        onMouseEnter={this.handleOnMouseEnter}
                        onMouseLeave={this.handleOnMouseLeave}
                        className="navDsn"
                      >
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          <UserMenu SignOut={this.SignOut}></UserMenu>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      className="text-white m-0 p-0 verticalaligncentertd"
                      variant=""
                      hidden={!this.context.userData.hideSignupLink}
                      onMouseEnter={this.handleOnMouseEnter}
                      onMouseLeave={this.handleOnMouseLeave}
                    >
                      &nbsp;
                      <BsPerson
                        style={{
                          color: this.context.theme.mainNavBarTextColor,
                        }}
                        size={"18px"}
                      ></BsPerson>
                      &nbsp;&nbsp;
                    </Button>
                  </OverlayTrigger>
                </Nav>

                <div
                  className="fixed-bottom border-0  m-0 p-0"
                  style={{
                    backgroundColor:
                      this.context.theme.mainNavBarBackgroundColor,
                  }}
                >
                  <Navbar
                    className="position-sticky fixed-bottom m-0 p-0  bg-white"
                    style={{ fontFamily: "Tahoma", color: "navy" }}
                  >
                    <Navbar.Collapse className="m-0 p-0 ">
                      <Nav className="border-0 m-0 p-0 w-100 justify-content-center">
                        <Button
                          as={Link}
                          variant=""
                          className=" align-middle my-1 mx-0 px-0 w-100 border-0 bg-white"
                          to="/QueriesPage"
                          style={{
                            backgroundColor: "#B5E0F6",
                            color: "black",
                          }}
                        >
                          <FaRegQuestionCircle
                            style={{
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                            size={"18px"}
                          />
                          &nbsp; Queries
                        </Button>
                      </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="m-0 p-0 ">
                      <Nav className="border-0 m-0 p-0 w-100 justify-content-center">
                        <Button
                          className=" align-middle my-1 mx-0 px-0 w-100 border-0 bg-white"
                          // size="sm"
                          style={{
                            backgroundColor: "#B5E0F6",
                            color: "black",
                          }}
                          onClick={(e) => {
                            if (this.context.userData.loginStatus != "active") {
                              this.OnLogin();
                            } else {
                              this.setState({ proceedToQuote: true });
                            }
                          }}
                        >
                          <TbCoinRupee size={22} />
                          &nbsp; Get Quote
                        </Button>
                      </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="m-0 p-0 ">
                      <Nav className="border-0 m-0 p-0 w-100 justify-content-center">
                        <Button
                          // size="sm"
                          style={{
                            backgroundColor: "#B5E0F6",
                            color: "black",
                          }}
                          className="align-middle my-1 mx-0 px-0 w-100 border-0 bg-white"
                          onClick={(e) => {
                            if (this.context.userData.loginStatus != "active") {
                              this.OnLogin();
                            } else {
                              this.setState({ proceedToCheckOut: true });
                            }
                          }}
                        >
                          <BsCash size={22} /> &nbsp;My Orders
                        </Button>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </>
            )}
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

class UserMenu extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const currentUrl = window.location.href;
    const parts = currentUrl.split(/\/+/).filter((part) => part !== "");
    return (
      <>
        <Row className="bg-whie text-center">
          <label className="bg-whie text-center">
            <h4>
              Hi{" "}
              {this.context.userData.firstName
                ? this.context.userData.firstName
                : "Guest!"}
            </h4>
          </label>
        </Row>
        <ListGroup variant="flush" className="fw-bold bg-dark">
          <ListGroup.Item
            className={parts[2] === "UserOrdersMobile" ? "navActive" : ""}
            as={Link}
            action
            variant="dark"
            // to="/UserOrdersMobile"
            to={
              this.context.theme.allProductToGetQuote == true
                ? "/PayView"
                : "/UserOrdersMobile"
            }
          >
            My Orders
          </ListGroup.Item>
          <ListGroup.Item
            className={parts[2] === "UserProfile" ? "navActive" : ""}
            as={Link}
            action
            variant="dark"
            to="/UserProfile"
          >
            My Profile
          </ListGroup.Item>
          {/* <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/UserChangePassword"
          >
            Change Password
          </ListGroup.Item> */}
          {/* <ListGroup.Item variant="dark" className="bg-dark"></ListGroup.Item> */}
          <ListGroup.Item
            className={parts[2] === "UserProfile" ? "UserCustomerSupport" : ""}
            as={Link}
            action
            variant="dark"
            to="/UserCustomerSupport"
          >
            Customer Support
          </ListGroup.Item>
          <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/home"
            onClick={(e) => {
              Swal.fire({
                title: "Warning!",
                confirmButtonColor: "#23B14D",
                cancelButtonColor: "#dbdbdb",
                text: "Are you sure you want to Signout?",
                showCancelButton: "true",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.context.clearUserData();
                  this.props.SignOut();
                }
              });
            }}
          >
            Sign Out
          </ListGroup.Item>
        </ListGroup>
      </>
    );
  }
}

export const ShopByCategoryMobile = (props) => {
  const {
    theme,
    mainCategories,
    subCategories,
    GetProductsByMainCategoryId,
    GetProductsBySubCategoryId,
    UpdateReloadProductListView,
  } = useContext(CartContext);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [subCategoryClicked, setSubCategoryClicked] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  const onMainCategoryClickEvent = (event) => {
    UpdateReloadProductListView("true");
    GetProductsByMainCategoryId(event.currentTarget.id, null, null);
    props.SubCategoryClicked();
  };

  const OnSubCategoryClickEvent = (e) => {
    UpdateReloadProductListView("true");
    GetProductsBySubCategoryId(e.syntheticEvent.currentTarget.id, null, null);
    props.SubCategoryClicked();
  };
  return (
    <>
      <div ref={ref} {...anchorProps}>
        <Button
          className={"dropdown-toggle my-3 border-0 bold_font"}
          style={{
            backgroundColor:
              theme.mainNavBarBackgroundColor == undefined
                ? "white"
                : theme.mainNavBarBackgroundColor,
            color: theme.mainNavBarTextColor,
          }}
        >
          <img
            src="/images/menu3.png"
            className="menumobile m-0 p-0 "
            width={20}
            height={20}
            style={{
              filter: "invert(" + theme.invertMenuIconOnMobile + "%)",
            }}
          ></img>
        </Button>
      </div>

      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
      >
        {mainCategories.map((maincategory) => (
          <>
            <SubMenu
              label={maincategory.name}
              className="my-2 fw-bold"
              id={maincategory.id}
            >
              {subCategories
                .filter(
                  (subcategory) => subcategory.mainCategoryId == maincategory.id
                )
                .map((subcategory) => (
                  <>
                    <Link to="/ProductsList" style={{ textDecoration: "none" }}>
                      <MenuItem
                        id={subcategory.id}
                        onClick={(e) => {
                          OnSubCategoryClickEvent(e);
                        }}
                        style={{
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {subcategory.name}
                      </MenuItem>
                    </Link>
                  </>
                ))}
            </SubMenu>
            {/* </div> */}
          </>
        ))}
      </ControlledMenu>
    </>
  );
};
